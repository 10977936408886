import React, { useRef, useEffect } from 'react';
import '../../ExampleTutorial1.css';
import { Comments } from '@hyvor/hyvor-talk-react';
import DropCap from '../../../Components/DropCap';
import ResponsiveIframe from '../../../Components/ResponsiveIframe';
import TextReveal from '../../../Components/TextReveal';
import AdSenseAd from '../../../Advertising/Ads';
import { initGA, logPageView } from '../../../analytics';
import { Helmet } from 'react-helmet';
import DropCap1 from '../../Travel/DropCap1';


const title = `${process.env.PUBLIC_URL}/Images/Articles/SummerBeauty/SummerBeautyTitle.png`;
const image1 = `${process.env.PUBLIC_URL}/Images/Articles/SummerBeauty/BlueLagoon.jpg`;
const image2 = `${process.env.PUBLIC_URL}/Images/Articles/SummerBeauty/barbiewpolaroid.JPG`;
const image3 = `${process.env.PUBLIC_URL}/Images/Articles/SummerBeauty/EmilyInParis.jpg`;
const image4 = `${process.env.PUBLIC_URL}/Images/Articles/SummerBeauty/floracopy.jpg`;
const image5 = `${process.env.PUBLIC_URL}/Images/Articles/SummerBeauty/H2Hair.jpg`;
const image6 = `${process.env.PUBLIC_URL}/Images/Articles/SummerBeauty/outerbanks.jpg`;
const image7 = `${process.env.PUBLIC_URL}/Images/Articles/SummerInspo/mermaidthumbnail.jpg`;

const signature = `${process.env.PUBLIC_URL}/Images/Articles/Signature.png`;

const Card = ({ blogContent }) => (
  <div>
    <div><TextReveal text='POP CULTURE' /></div>
    <div className="card">
      <div className="blog-content">{blogContent}</div>
    </div>
  </div>
);

const SummerBeauty = () => {
  const websiteId = '10910';
  const blogRef = useRef(null);

  useEffect(() => {
    initGA();
    logPageView('/summerbeauty');
}, []);

 
const headingStyle = {
  textAlign: 'center',
  fontSize: '1.5rem',
  fontWeight: 'normal',
  fontFamily: 'Arapey, serif',
  color: '#333',
  margin: '1.5rem'
};
const paragraphStyle = {
  fontSize: '20px',
  fontFamily: 'GFS Didot, serif',
  margin: '1rem 1.5rem',
  marginTop: '1rem',
  marginBottom: '1rem',
  maxWidth: '500px',
  lineHeight: '1.5',
  marginLeft: 'auto',
  marginRight: 'auto',
};
  const blogContent = (
    <div className="container">
     <Helmet>
  <title>Summer Beauty Inspiration - Jackie Wyers</title>
  <link rel="canonical" href="https://jackiewyers.beauty/summerbeauty" />

  <meta name="description" content="Explore summer beauty inspiration with Jackie Wyers. Discover iconic hairstyles, makeup looks, and fashion tips from pop culture's beloved characters." />
  <meta name="keywords" content="Summer Beauty, Hair and Makeup, Jackie Wyers, Beauty Blog, Pop Culture, The Blue Lagoon, Barbie Movie, Emily In Paris, Winx Club, H2O: Just Add Water, Outer Banks" />
  <meta property="og:title" content="Summer Beauty Inspiration - Jackie Wyers" />
  <meta property="og:description" content="Explore summer beauty inspiration with Jackie Wyers. Discover iconic hairstyles, makeup looks, and fashion tips from pop culture's beloved characters." />
  <meta property="og:image" content={title} />
  <meta property="og:url" content="https://jackiewyers.beauty/summerbeauty" />
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Summer Beauty Inspiration - Jackie Wyers" />
  <meta name="twitter:description" content="Explore summer beauty inspiration with Jackie Wyers. Discover iconic hairstyles, makeup looks, and fashion tips from pop culture's beloved characters." />
  <meta name="twitter:image" content={title} />
  <script type="application/ld+json">
    {`
    {
      "@context": "http://schema.org",
      "@type": "Article",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://jackiewyers.beauty/summerbeauty"
      },
      "headline": "Summer Beauty Inspiration - Jackie Wyers",
      "description": "Explore summer beauty inspiration with Jackie Wyers. Discover iconic hairstyles, makeup looks, and fashion tips from pop culture's beloved characters.",
      "image": "${title}",
      "author": {
        "@type": "Person",
        "name": "Jackie Wyers"
      },
      "publisher": {
        "@type": "Organization",
        "name": "Jackie Wyers",
        "logo": {
          "@type": "ImageObject",
          "url": "${title}"
        }
      },
      "datePublished": "2024-07-27",
      "dateModified": "2024-07-27"
    }
    `}
  </script>
  <script async src="https://www.googletagmanager.com/gtag/js?id=G-RT6GR7JXYG"></script>
  <script>
    {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-RT6GR7JXYG');
    `}
  </script>
  <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4660168246825318" crossorigin="anonymous"></script>
</Helmet>

      <div style={{ margin: '5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10vh' }}>
      <a href="/popculture" style={{ fontFamily: 'Arapey', fontSize: '15px', color: 'black', textDecoration: 'none' }}>
          BEAUTY // POP CULTURE
        </a>
      </div>
      <img src={title} alt="No Makeup-Makeup Spring Edition Title" style={{ width: '100%' }} />

      <div>
      <p style={{ margin: '2rem 0',textAlign: 'center', fontSize: '1rem', fontFamily: 'Arapey, sans-serif', maxWidth: '100%', fontStyle: 'italic' }}>
      All products featured are chosen by Jackie Wyers. We may earn commission on some of the items you choose to buy.
        </p>
      </div>

      <DropCap1 text="Looking for easy breezy summer hairstyles, makeup & style to try this
summer? Here’s my top 6 inspirations pulled from some of pop
culture's most iconic characters. From the sun-kissed shores of 'The
Blue Lagoon' to the chic streets of 'Emily In Paris,' join me as I recreate and
reinterpret the signature styles of these beloved characters. Whether you're
seeking a dash of nostalgic charm or a splash of summer-ready glamour,
these tutorials will inspire you to embrace your inner icon. " />
      <div
style = {{
  margin : '20px'
}}
>

  <AdSenseAd/>
</div>
     
      <h2 style={headingStyle}>The Looks:
      </h2>
      <h2 style={headingStyle}>
      Brooke Shields as Emmaline - ‘The Blue Lagoon'</h2>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <a href="https://www.instagram.com/p/C5dsVStuCqT/?img_index=1" target="_blank" rel="noopener noreferrer">
          <img src={image1} alt="Pheobe Tonkin" style={{ width: '100%', maxWidth: '650px' }} />
        </a>
      </div>
      <p style={{ textAlign: 'center', fontSize: '1.25rem', fontFamily: 'Arapey, sans-serif', margin: '0.5rem', marginBottom: '1rem', maxWidth: '100%', fontStyle: 'italic' }}>
      Original Makeup by Barbara Daly and Hair Design by Leonard George from 'The Blue Lagoon,' recreation by Jackie
      Wyers. </p>
      <p style={paragraphStyle}>

      Capture the iconic natural beauty of Brooke Shields in 'The Blue Lagoon'
with long, tousled flowing beach waves and a fresh-faced no-makeup
makeup look. Embrace the island vibe with a tan, minimal sun-kissed
makeup, and a touch of sea salt spray to enhance natural sea swept texture.
Dive into the full makeup and hair tutorial inspired by Brooke on my
channel, my <a href='https://www.youtube.com/watch?v=yC8Sf9U3Aww&list=PL-_qSuEl3UwH4UEWC4LNUSi6a3y_oVmDB'>most viewed tutorial!</a> And for a nostalgic throwback, check out
my very first attempt at creating this iconic style <a href='https://www.youtube.com/watch?v=yC8Sf9U3Aww&list=PL-_qSuEl3UwH4UEWC4LNUSi6a3y_oVmDB'> here.</a> 🌴 ✨   </p>

      <ResponsiveIframe src="https://shopmy.us/collections/public/619257?noHeader=true" title="Shop My Victoria's Secret Favorites" />
    

      <div
style = {{
  margin : '20px'
}}
>

  <AdSenseAd/>
</div>

      <h2 style={headingStyle}>
      Margot Robbie as Barbie - ‘The Barbie Movie’</h2>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <a href="https://www.instagram.com/p/C5dsVStuCqT/?img_index=1" target="_blank" rel="noopener noreferrer">
          <img src={image2} alt="Pheobe Tonkin" style={{ width: '100%', maxWidth: '650px' }} />
        </a>
      </div>
      <p style={{ textAlign: 'center', fontSize: '1.25rem', fontFamily: 'Arapey, sans-serif', margin: '0.5rem', marginBottom: '1rem', maxWidth: '100%', fontStyle: 'italic' }}>
      Original Makeup by Pati Dubroff and Hair Design by Bryce Scarlett from 'The Barbie Movie,' recreation by
      Jackie Wyers.  </p>
      <p style={paragraphStyle}>
      Embrace the fun and fabulous look of Margot Robbie's Barbie with
voluminous curls paired with a multicoloured visor. Add wearable pink
makeup, a matching bold outfit to embrace barbiecore. Barbie's perfectly
styled hair has tones of extensions in the film, so you might need some clip
in’s to get the volume down. Manifest your Barbie Dream house life with
this <a href='https://www.youtube.com/watch?v=JfaND9n9ZCU&t=1s'>step-by-step tutorial</a> on achieving Margot's Barbie glam, along with
wearable summer hairstyles for a beach Barbie <a href='https://www.youtube.com/watch?v=JfaND9n9ZCU&t=1s'> here.</a>   
   </p>
      <ResponsiveIframe src="https://shopmy.us/collections/public/619260?noHeader=true" title="Shop My Victoria's Secret Favorites" />
 

      <div
style = {{
  margin : '20px'
}}
>

  <AdSenseAd/>
</div>



      <h2 style={headingStyle}>
      Lilly Collins as Emily - ‘Emily In Paris'</h2>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <a href="https://www.instagram.com/p/C5dsVStuCqT/?img_index=1" target="_blank" rel="noopener noreferrer">
          <img src={image3} alt="Pheobe Tonkin" style={{ width: '100%', maxWidth: '650px' }} />
        </a>
      </div>
      <p style={{ textAlign: 'center', fontSize: '1.25rem', fontFamily: 'Arapey, sans-serif', margin: '0.5rem', marginBottom: '1rem', maxWidth: '100%', fontStyle: 'italic' }}>
      Original Makeup by Aurélie Payen and Hair Design by Mike Desir from 'Emily In Paris,' recreation
      by Jackie Wyers.   </p>
      <p style={paragraphStyle}>
      Channel the chic & quirky style of Lily Collins in 'Emily In Paris' with soft
waves paired with a beret, or a sleek bun for summer events. Instead of nomakeup makeup, maybe classic red lips are your vibe for a hint of Parisian
flair. Euro-girl summer with an eclectic touch screams 'Emily In Paris,' and I
can’t wait for the next season! Explore <a href='https://www.youtube.com/watch?v=b_e98iV-TdM&t=4s'>  my tutorial </a> on getting Emily's
Parisian chic look, perfect for any summer outing… especially if you are
visiting France 🇫🇷!   
   </p>
      <ResponsiveIframe src="https://shopmy.us/collections/public/619267?noHeader=true" title="Shop My Victoria's Secret Favorites" />
   
  


      <h2 style={headingStyle}>
      Flora - ‘Winx Club’ </h2>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <a href="https://www.instagram.com/p/C5dsVStuCqT/?img_index=1" target="_blank" rel="noopener noreferrer">
          <img src={image4} alt="Pheobe Tonkin" style={{ width: '100%', maxWidth: '650px' }} />
        </a>
      </div>
      <p style={{ textAlign: 'center', fontSize: '1.25rem', fontFamily: 'Arapey, sans-serif', margin: '0.5rem', marginBottom: '1rem', maxWidth: '100%', fontStyle: 'italic' }}>
      Original Character Design by Iginio Straffi and Animation by Rainbow S.p.A. from 'Winx Club,' recreation
by Jackie Wyers.   </p>
      <p style={paragraphStyle}>
      Transform into the magical Flora from 'Winx Club' with whimsical outfits,
flowing hair, and ethereal makeup. Embrace floral accessories, pastel hues,
and long brunette hair with a money piece—a pop of blonde through the
bangs to frame the face. This detail is what makes Flora’s character pop!
Dive into my tutorial for a fun, enchanting look that will make you feel like
you're part of the Winx Club. (This was a private video for a while, <a href='https://www.youtube.com/watch?v=2lZ-1p7mKMY&t=368s'>but it’s
    back</a> if you missed it!)  
   </p>
      <ResponsiveIframe src="https://shopmy.us/collections/public/619272?noHeader=true" title="Shop My Victoria's Secret Favorites" />

      <div
style = {{
  margin : '20px'
}}
>

  <AdSenseAd/>
</div>
      <h2 style={headingStyle}>
      Phoebe Tonkin as Cleo - ‘H2O: Just Add Water’</h2>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <a href="https://www.instagram.com/p/C5dsVStuCqT/?img_index=1" target="_blank" rel="noopener noreferrer">
          <img src={image5} alt="Pheobe Tonkin" style={{ width: '100%', maxWidth: '650px' }} />
        </a>
      </div>
      <p style={{ textAlign: 'center', fontSize: '1.25rem', fontFamily: 'Arapey, sans-serif', margin: '0.5rem', marginBottom: '1rem', maxWidth: '100%', fontStyle: 'italic' }}>
      Original Makeup by Jennifer Lamphee and Hair Design by Kylie Clarke from 'H2O: Just Add Water,' Season
      3, recreation by Jackie Wyers.   </p>
      <p style={paragraphStyle}>
      Ever wanted to channel your inner H2O mermaid without needing the tail?
The Y2K hairstyles from the show were simple, yet fun—and a personal
favorite episode and hairstyle was when Cleo was a bridesmaid. Her first
hairstyle really captured a 2000s beachy vibe and was very nostalgic to
recreate with two braids, a bun, and floral pins. Dive into the full makeup
and hair <a href='https://www.youtube.com/watch?v=0hVDAw6TznU&t=659s'> tutorial series</a> inspired by Cleo on my channel!
   </p>
      <ResponsiveIframe src="https://shopmy.us/collections/public/619285?noHeader=true" title="Shop My Victoria's Secret Favorites" />
    
    

      <h2 style={headingStyle}>
      Madison Bailey as Kiara from 'Outer Banks'</h2>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <a href="https://www.instagram.com/p/C5dsVStuCqT/?img_index=1" target="_blank" rel="noopener noreferrer">
          <img src={image6} alt="Pheobe Tonkin" style={{ width: '100%', maxWidth: '650px' }} />
        </a>
      </div>
      <p style={{ textAlign: 'center', fontSize: '1.25rem', fontFamily: 'Arapey, sans-serif', margin: '0.5rem', marginBottom: '1rem', maxWidth: '100%', fontStyle: 'italic' }}>
     
Original Makeup by Stacy Butterworth and Hair Design by Ann Minahan from 'Outer Banks,' recreation by Jackie Wyers.   </p>
      <p style={paragraphStyle}>
      Capture the adventurous and carefree style of Madison Bailey's Kiara from
'Outer Banks' with her messy bun and thick headband for a laid-back vibe. I
covered several more effortless styles from her character, with natural waves,
braids, and a touch of bronzer for that summer glow. Explore my step-bystep <a href='https://www.youtube.com/watch?v=Gk1OQ5D8Uwg&t=1s'> tutorial</a> on getting Kiara's summer-ready hair and makeup over on
YouTube  </p>
   
      <ResponsiveIframe src="https://shopmy.us/collections/public/619322?noHeader=true" title="Shop My Victoria's Secret Favorites" />
     
      <div
style = {{
  margin : '20px'
}}
>

  <AdSenseAd/>
</div>
      <h2 style={headingStyle}>
      A Final Note 🌊 </h2>

      <p style={paragraphStyle}>
      Embrace summer with these character-inspired hair, makeup, and style
inspirations—a fun way to relive your favorite pop culture moments while
adding subtle glamour to your summer look. Follow along for tutorials and
don’t forget to share your recreations with me by tagging me on socials
@jackiewyers —I love seeing your versions of the looks and would be
thrilled to feature them on my site. 
      </p>
      <p style={paragraphStyle}>
      For more pop culture and makeup inspiration, check out my previous
articles and tutorials. Don't hesitate to leave a comment below if there are
any pop culture icons I've missed that are your favourites! Thanks for
reading, and I hope you’re summer is filled with happy memories. 
      </p>



      <div style={{ textAlign: 'center', margin: '2rem 0' }}>
        <a href="/summerinspo" style={{ fontFamily: 'Arapey', fontSize: '1.25rem', color: 'black', textDecoration: 'underline', textAlign: 'center' }}>
          <img src={image7} alt="Margot Robbie - Barbie Movie" style={{ width: '100%', maxWidth: '500px', margin: '0 auto', display: 'block' }} />
          MORE POP CULTURE BEAUTY →
        </a>
      </div>

      <div>
        <img src={signature} alt="Signature" style={{ width: '100%' }} />
      </div>
    
     
<Comments website-id={websiteId} page-id={"Las-nozzfffflestronsonberries"} />

    </div>
  );

  return (
    <div ref={blogRef}>
      <Card
        title="Summer Beauty Inspiration!"
        description="Just a hint of color, just in time for spring"
        blogContent={blogContent}
      />

    </div>
  );
};

export default SummerBeauty;
